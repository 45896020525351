@import "styles/variables.scss";

.updateComponent_inputLabels {
  position: relative;
  font-weight: 500 !important;
  font-size: 1rem !important;
  top: 0.4rem !important;
}
.updateComponent_inputFields {
  margin: 0.5rem 0 !important;
  height: 3.2rem;
  font-size: 1rem !important;
  background-color: #eff3ff !important;
  border: none;
  border-bottom: 2px solid $alternateViolet;
  border-radius: 6px 6px 0 0 !important;
  transition: all 0.3s ease-in-out;
}

.createComponent_inputFields {
  height: 2.66rem;
  font-size: 1rem !important;
  border: none;
  border: 2px solid $alternateViolet;
  border-radius: 6px 6px 0 0 !important;
  transition: all 0.3s ease-in-out;
  margin-top: 0.14rem !important;
}

.createComponent_inputNumberField {
  width: 100% !important;
  & > div:nth-child(2) > input {
    height: 2.66rem !important;
  }
}

.classifier_subClassInputLabels {
  display: block;
  position: relative;
  font-weight: 500 !important;
  font-size: 1rem !important;
}

.searchImg_filter {
  margin-top: 0 !important;
  border: 2px solid $primaryColor;
  border-radius: 4px !important;
  font-size: 1.04rem !important;
}

.searchImg_filter:focus {
  outline: none !important;
  border: 2px solid $primaryColor;
  border-radius: 4px !important;
}

.classifier_subClassEditIcon {
  border-radius: 6px !important;
  width: 60px !important;
  height: 37px !important;
  padding: 0.38rem 0 !important;
  background-color: #fff !important;
  border: 1px solid $secondaryColor;
  margin-right: 0.5rem !important;
  transition: all 0.2s ease-in-out;
  font-size: 0.8rem !important;
  color: $dangerColor;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: $dangerColor;
    color: #fff !important;
  }
}
