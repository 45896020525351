@import "styles/variables.scss";

.createMap_addNew {
  position: relative;
  top: 1rem !important;
  display: inline !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  color: $primaryColor;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.createMap_inputLabels {
  position: relative;
  display: block !important;
  margin-top: 1.15rem !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
  &:nth-child(1) {
    margin-top: 0rem !important;
  }
}
.createMap_inputField {
  width: 100% !important;
  &:last-child {
    margin-bottom: 1rem !important;
  }
  & > div {
    height: 3rem !important;
    border: 2px solid $primaryColor;
    border-radius: 4px !important;
    font-size: 1rem !important;
    // margin-left: 2rem !important;
    & > span:nth-child(2) {
      margin: auto 0 !important;
    }
  }
  & > Option {
    font-size: 1.2rem !important;
  }
}
