@import "styles/variables.scss";

.manageUsers__container {
  padding-right: 2rem;
}

.manageUsers_heading {
  font-size: 1.2rem;
  font-weight: 500 !important;
  color: $primaryColor;
  margin: 0 !important;
  white-space: nowrap;
}

.manageUsers__searchFilter {
  width: 14rem !important;
  height: 2.6rem;
  // margin: 2rem 0 0 !important;
  border: 2px solid $primaryColor;
  border-radius: 4px;
}
.manageUsers__searchFilterIcon {
  margin-right: 2px !important;
  font-size: 1.36rem;
  color: #a1a1a1 !important;
}
.manageUsers__selectFilter {
  & > div {
    height: 2.6rem !important;
    border: 2px solid $primaryColor;
    border-radius: 4px !important;
    font-size: 1.04rem !important;
    & > span:nth-child(2) {
      margin: auto 0 !important;
    }
  }
  & > Option {
    font-size: 1.2rem !important;
  }
}

.manageUsers_filterHeading {
  font-size: 1.04rem !important;
  font-weight: 500 !important;
  display: inline;
  color: $primaryColor;
  margin-right: 0.8rem !important;
  white-space: nowrap;
}

.table {
  margin-top: 2rem;
}

.loadingDiv {
  position: relative;
  width: 100%;
  height: 33rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.manageUsers__alertbox {
  position: fixed;
  top: 50px;
  right: 50%;
  transform: translateX(50%);
  z-index: 100;

  & > div {
    border-radius: 7px;
  }
}
