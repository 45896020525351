@import "antd/dist/antd.css";
@import "./normalize.css";
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;800&display=swap");

* {
  font-family: "Rubik", sans-serif;
}

.ant-layout {
  min-height: 100vh !important;
}

// Sidebar
// .ant-menu-submenu {
// height: 2.2rem !important;
// }
.ant-menu-submenu-open {
  height: auto !important;
}
.ant-menu-item-selected a {
  color: #6254ff;
  &:hover {
    color: #6254ff;
  }
}
.ant-menu-item a:hover {
  color: #6254ff !important;
}
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #6254ff;
}
// .ant-menu > .ant-menu-item:hover,
// .ant-menu > .ant-menu-submenu:hover,
.ant-menu > .ant-menu-item-active,
// .ant-menu> .ant-menu-submenu-active,
.ant-menu > .ant-menu-item-open,
// .ant-menu> .ant-menu-submenu-open,
.ant-menu > .ant-menu-item-selected,
.ant-menu > .ant-menu-submenu-selected {
  color: #6254ff;
  background-color: #e6e9ff !important;
  &:hover {
    color: #6254ff;
    background-color: #e6e9ff;
  }
  &::selection {
    background: #e6e9ff !important;
  }
}

// Spin loader text
.ant-spin-text {
  color: #626df6;
}
// Radio
.ant-radio-checked .ant-radio-inner {
  border-color: #6254ff !important ;
}
.ant-radio-checked .ant-radio-inner:after {
  background-color: #6254ff;
}
// Checkbox
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #6254ff;
  border-color: #6254ff;
}
.ant-checkbox-wrapper .ant-checkbox-inner,
.ant-checkbox .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: grey !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #6254ff !important;
}

.ant-modal-content {
  border-radius: 6px !important;
  z-index: 2 !important;
  & > .ant-modal-footer {
    & > button {
      height: 2.4rem !important;
      border-radius: 4px !important;
      font-size: 1rem !important;
    }
    & > .ant-btn-primary {
      background-color: #6254ff !important;

      &:hover {
        background-color: #594bff !important;
      }
    }
  }
  // For info modal
  & > div > div {
    & > div:nth-child(1) > .anticon > svg {
      fill: #6254ff !important;
    }
    & > div:nth-child(2) > button {
      background-color: #6254ff !important;
      border-color: #6254ff !important;
    }
  }
}

.ant-popover-content {
  z-index: 1 !important;
}

// Table
.ant-table-thead .ant-table-cell {
  background-color: #fff;
}

// Tabs
.ant-tabs-tab-active > div {
  color: #6254ff !important;
}
.ant-tabs-ink-bar {
  background: #6254ff !important;
}
//Switch
.ant-switch-checked {
  background: #6254ff !important;
}
// Tooltip
.ant-tooltip {
  padding-top: 0;
}
.ant-tooltip-inner {
  margin-top: 0;
  top: 0;
}
.ant-tooltip-placement-bottom {
  height: 1px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  height: 0;
}
// Collapse
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  & > .ant-collapse-expand-icon {
    display: none;
  }
  & > .ant-collapse-header-text {
    width: 100%;
  }
}
.markerjsImgElement {
  width: auto !important;
  & > div > div:nth-child(2) > div > img {
    width: 100% !important;
    height: 58vh !important;
  }
}
.markerjsColorSetHorizontalScoll {
  & > div:nth-child(1) {
    & > div {
      overflow-y: hidden !important;
      overflow-x: scroll !important;
      white-space: nowrap !important;
      margin: 10px !important;
      padding-bottom: 60px !important;
    }
  }
}

@media (max-width: 768px) {
  .ant-radio {
    top: 0 !important;
  }
}

.ant-table {
  padding-left: 0 !important;
}
