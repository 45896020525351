@import "styles/variables.scss";

.filterComponent_inputLabels {
  position: relative;
  font-weight: 500 !important;
  font-size: 1rem !important;
  top: 0.4rem;
}
.filterComponent_inputFields {
  margin: 0.5rem 0 !important;
  height: 3.2rem;
  font-size: 1rem !important;
  background-color: #eff3ff !important;
  border: none;
  border-bottom: 2px solid $alternateViolet;
  border-radius: 6px 6px 0 0 !important;
  transition: all 0.3s ease-in-out;
}
.filterComponent_dropdowns {
  width: 100%;
  margin-top: 0.5rem !important;
  & > div {
    height: 3.2rem !important;
    background-color: #eff3ff !important;
    border: none !important;
    border-bottom: 2px solid $alternateViolet;
    border-radius: 6px 6px 0 0 !important;
    & > span:nth-child(2) {
      margin: auto !important;
    }
  }
  & > span {
    color: $primaryColor;
  }
}

.reviewBucketCountImgHeadingDiv {
  margin: 1rem auto;
}
.reviewBucket_toggleBtnHeading {
  margin-right: 1rem;
}
.reviewBucket_toggleBtnHeadingDiv {
  position: absolute;
  right: 0;
}
.reviewBucket_imgSliderCount {
  position: relative;
  top: 45%;
  transform: translateY(-50%);
  padding-left: 0.5rem;
}
.reviewBucket_sliderOverallDatasetImagesCount {
  position: relative;
  top: 45%;
  transform: translateY(-50%);
  padding-left: 0.3rem !important;
  color: $primaryColor;
}
.reviewBucket_toggleBtn {
  background: $primaryColor;
}
