@import "styles/variables.scss";

.table_annotationsHeading {
  font-size: 1.2rem !important;
  font-weight: 500 !important;
  color: $primaryColor;
}
.table {
  width: 99% !important;
  margin: 1.2rem 0 3rem !important;
  & > div > div > div {
    padding-left: 1rem !important;
    font-size: 1rem !important;
  }
  th {
    min-width: 200px !important;
  }
}
.tableCol_infoIcon {
  margin-left: 0.5rem !important;
  font-size: 1rem !important;
  color: $secondaryColor;
  vertical-align: middle;
  padding-bottom: 0.15rem !important;
}
