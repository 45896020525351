@import "styles/variables.scss";

.wrapper {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important   ;
}

.images_bucketsCol_goBackBtn {
  margin: 0.8rem 0 0 !important;
  & > span {
    cursor: pointer;
    margin-left: 0.8rem !important;
    &:hover {
      color: $primaryColor;
    }
  }
}
.images_bucketsColDiv {
  min-height: 50vh !important;
  height: 55%;
  overflow-y: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}
.annotationToggleParentDiv {
  position: relative;
  height: 25vh;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  // border: 2px solid black;
}
.annotationToggleDiv {
  position: relative;
  width: 100%;
  margin: 2rem 1rem 0rem;
}
.images_bucketNameCard {
  background-color: #fff;
  margin: 0.8rem !important;
  box-shadow: 0 0.2rem 0.4rem rgba(47, 47, 47, 0.2);
  border-radius: 4px;
}
.images_bucketNameCard_name {
  font-size: 1.04rem !important;
  font-weight: 500 !important;
  padding: 1rem 0 1rem 0.8rem !important;
}
@media (max-width: $bp-md) {
  .images_bucketsColDiv {
    min-height: 49vh;
  }
  .annotationToggleParentDiv {
    height: 10vh;
    width: 80%;
    margin-top: 2rem;
  }
}

@media (min-height: 850px) {
  .annotationToggleParentDiv {
    height: 45vh;
  }
}
