@import "styles/variables.scss";

.container {
  height: 100vh;
  overflow: hidden;
}
.container_loginImg {
  width: 95%;
  height: 100vh;
  object-fit: cover;
}
.container_loginContent {
  margin-top: 5rem;
  margin-left: 6vw;
  width: 75%;
}
.container_loginLogo {
  color: #000865 !important;
  & > span {
    color: $primaryColor;
    font-weight: 400 !important;
  }
}
.container_loginDescription {
  font-weight: 500 !important;
  font-size: 1.42rem !important;
  margin: 0 0 3.3rem !important;
}
.login_inputLabels {
  position: relative;
  font-weight: 500 !important;
  font-size: 1.25rem !important;
  top: 0.4rem !important;
}
.login_inputFields {
  height: 3.7rem;
  background-color: #e8edff !important;
  border: 2px solid $primaryColor;
  border-radius: 6px !important;
  & > input {
    background-color: #e8edff !important;
  }
}
.login_submitBtn {
  height: 3.4rem !important;
  background-color: $primaryColor;
  border: 2px solid $primaryColor;
  border-radius: 6px !important;
  font-size: 1.3rem !important;
  margin-top: 1rem !important;
  &:hover {
    background-color: #594bff !important;
    border: 2px solid #594bff !important;
  }
}
.version_styles {
  color: #757676;
  // font-size: 1rem;
  // letter-spacing: 3px;
  // font-weight: bold;
}

@media (max-width: $bp-md) {
  // .container {
  //   overflow-y: scroll;
  // }
  .container_imgCol {
    display: none;
  }
  .container_loginContent {
    margin-left: 7vw;
    width: 85%;
  }
}
