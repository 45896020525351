@import "styles/variables.scss";

.navbar {
  position: relative;
  display: flex;
  padding: 0;
  background-color: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  z-index: 1;
}
.navLogoImgRoute {
  position: relative;
  left: 0.8rem;
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  font-weight: 700;
  font-size: 25px;
  color: #000865;
  & > span {
    color: #6571ff;
    font-weight: 300;
  }
  &:hover {
    color: #000865;
  }
}
.goHomeBtn {
  margin: auto 2rem auto 0 !important;
  border: 1.6px solid $alternateViolet;
  &:hover {
    color: $primaryColor;
  }
}
.navbar_profile {
  display: inline;
  margin: auto 2rem auto 0 !important;
  font-weight: 500 !important;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  & > span {
    margin: auto 0 !important;
    vertical-align: middle;
  }
}
.navbar_profile_userIcon {
  font-size: 1.53rem;
  margin-right: 0.25rem;
}
.navbar_popupLink {
  min-width: 7rem;
  min-height: 28px;
  margin: 0;
  padding: 0.25rem 0.5rem !important;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  cursor: pointer;
  & > span {
    font-size: 1.3rem;
    margin-right: 0.5rem;
  }
  &:hover {
    background-color: #f5f5f5;
  }
}

@media (max-width: $bp-md) {
  .navbar {
    justify-content: space-between;
  }
  .goHomeBtn {
    display: none;
  }
  .navbar_menuIcon {
    font-size: 1.4rem;
    padding-left: 1.35rem;
    margin: auto 0 !important;
  }
  .navbar_profile {
    margin-right: 1.3rem !important;
  }
  .navbar_profile_userIcon {
    font-size: 2.3rem;
    margin-right: 0rem;
  }
  .navbar_profile_arrowIcon {
    display: none;
  }
  .navbar_popupLink {
    min-width: 10rem !important;
    min-height: 20px;
    font-size: 1.2rem !important;
    padding: 0.45rem 0.5rem !important;
  }
}
