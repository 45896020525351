@import "styles/variables.scss";

.loadingDiv {
  position: relative;
  width: 100%;
  height: 30rem;
}
.loadingSpinIcon {
  font-size: 20px;
  color: $alternateViolet;
}
.loadingDiv_spin {
  position: relative;
  margin: auto 0 !important;
  left: 50%;
  margin-top: 2.5rem !important;
  top: 50%;
  transform: translate(-50%, -50%);
}
.metrics_heading {
  font-size: 1.2rem;
  font-weight: 500 !important;
  color: $primaryColor;
  margin-top: 1rem !important;
  margin-bottom: 0 !important;
}
.metrics_createBtn {
  float: right;
  height: 2.8rem !important;
  border-radius: 4px !important;
  font-size: 1rem !important;
  margin: 1.4rem 1rem 0 0 !important;
  padding: 0 1.75rem;
  color: #fff !important;
  background-color: $primaryColor;
  border: none;
  &:hover {
    background-color: #594bff !important;
  }
}
.metrics_paginationDiv {
  margin: 2.5rem 1rem 1rem 0 !important;
  float: right;
}
.metris_downloadPending {
  font-weight: 500 !important;
  color: #b5b5b5 !important;
  margin-top: 0.4rem;
}
.metrics_downloadBtn {
  height: 2.8rem !important;
  border-radius: 4px !important;
  font-size: 1rem !important;
  padding: 0 1.3rem;
  color: #fff !important;
  background-color: $primaryColor;
  border: none;
  &:hover {
    background-color: #594bff !important;
  }
}
.metrics_table {
  width: 99% !important;
  margin: 1.2rem 0 3rem !important;
  & > div > div > div {
    padding-left: 1rem !important;
    font-size: 1rem !important;
  }
}
.metrics__searchFilterIcon {
  margin-right: 2px !important;
  font-size: 1.36rem;
  color: #a1a1a1 !important;
}
.metrics__searchFilter {
  width: 14rem !important;
  height: 2.6rem;
  // margin: 2rem 0 0 !important;
  border: 2px solid $primaryColor;
  border-radius: 4px;
}
.metrics_column {
  padding-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}
