// Breakpoints
$bp-xl: 1200px;
$bp-lg: 992px;
$bp-md: 768px;
$bp-sm: 576px;
$bp-xs: 320px;

// Spacing - margin, padding, gap(flex, grid) etc
$sp-xl: 24px;
$sp-lg: 16px;
$sp-md: 12px;
$sp-sm: 8px;
$sp-xs: 4px;

// Colors
$primaryColor: #6254ff !important;
$secondaryColor: #5e5e5e !important;
$alternateViolet: #626df6 !important;
$dangerColor: #f66262 !important;
