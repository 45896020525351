@import "styles/variables.scss";

.updateComponent_inputLabels {
  position: relative;
  font-weight: 500 !important;
  font-size: 1rem !important;
  top: 0.4rem !important;
}
.updateComponent_inputFields {
  margin: 0.5rem 0 !important;
  height: 3.2rem;
  font-size: 1rem !important;
  background-color: #eff3ff !important;
  border: none;
  border-bottom: 2px solid $alternateViolet;
  border-radius: 6px 6px 0 0 !important;
  transition: all 0.3s ease-in-out;
}
.popoverBtn {
  // background-color: $alternateViolet;
  height: 2.8rem !important;
  width: 2.8rem !important;
  border-radius: 6px !important;
  cursor: pointer;
  font-size: 1rem !important;
  color: #fff !important;
  border: 2px solid;
}
.popoverContent_heading {
  position: relative;
  font-size: 0.9rem !important;
  color: rgb(79, 79, 79);
}
.popoverContent {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  height: 8rem;
  overflow-y: scroll;
  padding-right: 0.5rem !important;
  /* Scrollbar width */
  &::-webkit-scrollbar {
    width: 6.5px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px !important;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.popoverContentColor {
  cursor: pointer;
  width: 25px;
  height: 25px;
  border-radius: 6px;
  margin: 0 5px 7px;
}
.loadingDiv {
  position: relative;
  width: 100%;
  height: 33rem;
}
.updateModalDiv {
  display: flex;
  align-items: center;
  width: 100%;
}
.classifier_subClassInputColorDiv {
  margin-left: 1rem !important;
  margin-right: 3rem;
  display: flex;
  flex-direction: column;
}
.updateModalInput {
  width: 60%;
}
.loadingSpinIcon {
  font-size: 20px;
  color: $alternateViolet;
}
.loadingDiv_spin {
  position: relative;
  margin: auto 0 !important;
  left: 50%;
  margin-top: 2.5rem !important;
  top: 50%;
  transform: translate(-50%, -50%);
}

.classifier_subClassInputLabels {
  display: block;
  position: relative;
  // left : 0.7rem;
  font-weight: 500 !important;
  font-size: 1rem !important;
}

.createComponent_inputFields {
  height: 2.66rem;
  font-size: 1rem !important;
  border: none;
  border: 2px solid $alternateViolet;
  border-radius: 6px 6px 0 0 !important;
  transition: all 0.3s ease-in-out;
  margin-top: 0.14rem !important;
  margin-left: 0.7rem;
}

.createComponent_inputNumberField {
  width: 100% !important;
  margin-left: 0;
  height: 3.1rem;
  & > div:nth-child(2) > input {
    height: 2.8rem !important;
    background-color: #eff3ff !important;
  }
}
