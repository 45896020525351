@import "styles/variables.scss";

.viewDatasets__container {
  padding-right: 2rem;
}

.datasets_headingRow {
  margin-top: 1.3rem !important;
  width: 97%;
}
.datasets_searchBarRow {
  width: 97%;
}
.datasets_heading {
  font-size: 1.2rem;
  font-weight: 500 !important;
  color: $primaryColor;
  margin: 0 !important;
}
.datasets_description {
  font-size: 1.1rem !important;
  font-weight: 400 !important;
  margin: 1rem 0 0 !important;
  & > hr {
    display: inline;
    height: 1.8rem !important;
    margin: 0 0.6rem !important;
    border: none;
    border-left: 1px solid #000 !important;
  }
  & > .datasets_descriptionCamera {
    margin-right: 0.3rem !important;
    margin-bottom: 0.25rem !important;
  }
}
.datasets_searchBox {
  width: 100% !important;
  height: 2.6rem;
  // margin: 2rem 0 0 !important;
  border: 2px solid $primaryColor;
  border-radius: 4px;
}
.datasets_searchBoxIcon {
  margin-right: 2px !important;
  font-size: 1.36rem;
  color: #a1a1a1 !important;
}
.datasets_filterHeading {
  font-size: 1.04rem !important;
  font-weight: 500 !important;
  display: inline;
  color: $primaryColor;
  margin-right: 0.8rem !important;
  white-space: nowrap;
}
.datasets_filter {
  & > div {
    height: 2.6rem !important;
    border: 2px solid $primaryColor;
    border-radius: 4px !important;
    font-size: 1.04rem !important;
    & > span:nth-child(2) {
      margin: auto 0 !important;
    }
  }
  & > Option {
    font-size: 1.2rem !important;
  }
}
.filterCol {
  position: absolute;
  bottom: 0 !important;
  right: 0 !important;
}
.loadingDiv {
  position: relative;
  width: 100%;
  height: 33rem;
}
.loadingSpinIcon {
  font-size: 20px;
  color: $alternateViolet;
}
.loadingDiv_spin {
  position: relative;
  margin: auto 0 !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.datasets_tableDiv {
  margin-top: 2.5rem !important;
  margin-bottom: 4rem !important;
}
.table {
  width: 97% !important;
  margin-top: 1.8rem !important;
  & > div > div > div {
    // padding-left: 1.5rem !important;
    padding-left: 0 !important;
    font-size: 1rem !important;
  }
}
.datasets_viewDataIconBtns {
  width: 44px !important;
  height: 44px !important;
  border-radius: 50% !important;
  background-color: #fff !important;
  border: 1px solid $secondaryColor;
  margin-right: 1rem !important;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  & > :nth-child(n) {
    color: $secondaryColor;
    position: relative;
    left: 50%;
    top: 38%;
    transform: translate(-50%, -50%);
    transition: all 0.2s ease-in-out;
    font-size: 1.15rem !important;
  }
  &:hover {
    transition: all 0.2s ease-in-out;
    background-color: #e0ddff !important;
    border: 1px solid $primaryColor;

    & > :nth-child(n) {
      transition: all 0.2s ease-in-out;
      color: $primaryColor;
    }
  }
}
.datasetsTableCol_infoIcon {
  margin-left: 0.5rem !important;
  font-size: 1rem !important;
  color: $secondaryColor;
  vertical-align: middle;
  padding-bottom: 0.15rem !important;
}
.classifier_subClassEditIcon {
  border-radius: 6px !important;
  width: 40px !important;
  height: 37px !important;
  padding: 0.38rem 0 !important;
  background-color: #fff !important;
  border: 1px solid $secondaryColor;
  margin-right: 0.5rem !important;
  transition: all 0.2s ease-in-out;
  font-size: 0.8rem !important;
  color: $primaryColor;
  cursor: pointer;
  &:hover {
    background-color: $primaryColor;
    color: #fff !important;
  }
}
.classifier_subClassDelIcon {
  border-radius: 6px !important;
  width: 60px !important;
  height: 37px !important;
  padding: 0.38rem 0 !important;
  background-color: #fff !important;
  border: 1px solid $secondaryColor;
  margin-right: 0.5rem !important;
  transition: all 0.2s ease-in-out;
  font-size: 0.6rem !important;
  color: $dangerColor;
  cursor: pointer;
  &:hover {
    background-color: $dangerColor;
    color: #fff !important;
  }
}
.deleteModal_deleteBtn {
  border: 2px solid $dangerColor;
  background-color: transparent !important;
  color: $dangerColor;
  &:hover {
    background-color: $dangerColor;
    color: #fff !important;
  }
}
.dataset_suspendBtns {
  border: none;
  border-radius: 4px !important;
  font-size: 0.85rem !important;
  margin: 0 0.2rem !important;
  height: 2.3rem !important;
}

@media (max-width: 768px) {
  .datasets_heading {
    display: block !important;
  }
  .filterCol {
    position: relative;
    margin-top: 1rem !important;
  }
  .datasets_tableDiv {
    margin-top: 1.5rem !important;
  }
  .table {
    width: 97% !important;
    margin-top: 0rem !important;
  }
}
