@import "styles/variables.scss";

.img_gallery_container {
  display: flex;
  align-items: center;
}

.datasets_headingRow_imgGallery {
  // margin-top: 1.3rem !important;
  width: 100%;
}
.datasets_heading {
  width: 100%;
  font-size: 1.2rem;
  font-weight: 500 !important;
  color: $primaryColor;
  // margin: 0 0 1.5rem !important;
  margin: 0 0 0 !important;
}
.datasets_description {
  font-size: 0.9rem;
  font-weight: 400 !important;
  color: $secondaryColor;
  width: 60%;
  margin: 0 !important;
}
.datasets_searchBox {
  width: 60% !important;
  height: 2.8rem;
  margin: 0.3rem 0 0 !important;
  // margin: 2rem 0 0 !important;
  border: 2px solid $primaryColor;
  border-radius: 4px;
}
.datasets_searchBoxIcon {
  margin-right: 2px !important;
  font-size: 1.36rem;
  color: #a1a1a1 !important;
}
.datasets_searchBtn {
  height: 2.8rem;
  margin: 0.3rem 0 1rem 1rem !important;
  // margin: 2rem 0 0 !important;
  border-radius: 4px;
  background-color: $primaryColor;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  padding: 0 1.4vw !important;
}
.datasets_pagination {
  position: relative;
  // margin: 1rem 0 0 !important;
  margin: 0 1.5rem 0 0 !important;
  float: right;
  display: flex;
}
.loadingDiv {
  position: relative;
  width: 100%;
  height: 33rem;
}
.loadingSpinIcon {
  font-size: 20px;
  color: $alternateViolet;
}
.loadingDiv_spin {
  position: relative;
  margin: auto 0 !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.table {
  width: 97% !important;
  margin-top: 1.8rem !important;
  & > div > div > div {
    // padding-left: 1.5rem !important;
    padding-left: 0 !important;
    font-size: 1rem !important;
  }
}
.datasets_editBtn {
  display: block !important;
  height: 2.8rem !important;
  border: 2px solid $alternateViolet;
  border-radius: 4px !important;
  font-size: 1rem !important;
  &:hover {
    color: #fff !important;
    background-color: #594bff !important;
    border: 2px solid #594bff !important;
  }
}
.datasets_editBtnIcon {
  vertical-align: middle;
  font-size: 1.05rem;
  margin-bottom: 0.3rem;
  margin-left: 0.3rem;
}
.datasets_browseBtn {
  display: block !important;
  height: 2.8rem !important;
  background-color: $alternateViolet;
  border: 2px solid $alternateViolet;
  border-radius: 4px !important;
  font-size: 1rem !important;
  color: #fff !important;
  &:hover {
    background-color: #594bff !important;
    border: 2px solid #594bff !important;
  }
}

.categories_row {
  width: 97%;
  margin-top: 1.5rem !important;
}
.categories_col {
  margin-top: 1rem !important;
}
.categories_card {
  width: 90%;
  padding: 0.8rem 1.2rem 1rem !important;
  border-radius: 6px !important;
  background-color: #fff !important;
  box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.06);
}
.categories_cardEditBtn {
  padding: 0rem 0.6rem 0.2rem !important;
  margin-left: 1rem !important;
  border-radius: 4px !important;
  &:nth-child(n) {
    font-size: 1.45rem !important;
  }
  &:hover {
    background-color: #dedbff !important;
    color: $primaryColor;
    cursor: pointer;
  }
  &:nth-child(2) {
    &:hover {
      background-color: #ffe2db !important;
      color: #ff4a4a !important;
    }
  }
}
.categoriesCard_type {
  font-size: 1.66rem !important;
  font-weight: 500 !important;
  margin-bottom: 0 !important;
}
.datasetsTableCol_infoIcon {
  margin-left: 0.5rem !important;
  font-size: 1rem !important;
  color: $secondaryColor;
  vertical-align: middle;
  padding-bottom: 0.15rem !important;
}
.categoriesCard_name {
  font-size: 0.9rem !important;
  font-weight: 400 !important;
  color: $secondaryColor;
  text-transform: uppercase;
  margin-top: 0.35rem !important;
}
.categoriesCard_dbAndImgsRow {
  margin: 1.3rem 0 1rem !important;
}
.categoriesCard_dbAndImgs {
  font-weight: 400 !important;
  font-size: 1.15rem !important;
  & > span {
    position: relative;
    top: 0.13rem;
    left: 0.27rem;
    color: $primaryColor;
  }
}
.categoriesCard_btn {
  display: block !important;
  height: 2.8rem !important;
  background-color: $primaryColor;
  border: 2px solid $primaryColor;
  border-radius: 4px !important;
  font-size: 1.12rem !important;
  margin: 1rem 0 0 auto !important;
  color: #fff !important;
  &:hover {
    background-color: #594bff !important;
    border: 2px solid #594bff !important;
  }
}
.categoriesCard_btnArrowIcon {
  margin-left: 0.5rem !important;
  font-size: 1.08rem;
}
.cameras_infoIcon {
  margin-left: 0.5rem !important;
  font-size: 1rem !important;
  color: $secondaryColor;
  vertical-align: middle;
  padding-bottom: 0.15rem !important;
}
.totalAnnotations_by_date {
  font-size: 1rem;
  font-weight: 500 !important;
  color: $primaryColor;
  margin: 0 0 1.5rem !important;
}

@media (max-width: $bp-md) {
  .datasets_description {
    width: 100%;
  }
  .datasets_searchBox {
    float: left;
    width: 90% !important;
    height: 3.2rem;
    margin: 1rem 0 0 !important;
  }
  .datasets_searchBtn {
    margin: 0.6rem 0 1rem !important;
    padding: 0 2.4rem !important;
  }
  .categories_row {
    width: 100%;
  }
  .categories_card {
    width: 100%;
  }
  .datasets_tableRow {
    margin-bottom: 4rem !important;
  }
}

.gallery_container {
  width: 100%;
  margin-top: 2rem !important;
  gap: 1rem;
  padding-right: 1.2rem;
}

.gallery_image_container {
  width: 100%;
  height: 100%;
  // border: 2px solid green;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0.725rem;
}

.img_thumb {
  width: 13rem;
  cursor: pointer;
}

.parent_container {
  display: block;
  margin: auto;
}

.bucket_filter {
  margin-top: 1rem !important;
}

.radio_button {
  margin-right: 0.5rem;
  // border: none;
  outline: none;
}
