@import "styles/variables.scss";

.classifier_filterHeading {
  font-size: 1.04rem !important;
  font-weight: 500 !important;
  display: inline;
  color: $primaryColor;
  margin-right: 0.8rem !important;
}
.classifier_filter {
  & > div {
    height: 2.8rem !important;
    border: 2px solid $primaryColor;
    border-radius: 4px !important;
    font-size: 1.04rem !important;
    & > span:nth-child(2) {
      margin: auto 0 !important;
    }
  }
  & > Option {
    font-size: 1.2rem !important;
  }
}

.loadingDiv {
  position: relative;
  width: 100%;
  height: 33rem;
}
.loadingSpinIcon {
  font-size: 20px;
  color: $alternateViolet;
}
.loadingDiv_spin {
  position: relative;
  margin: auto 0 !important;
  left: 50%;
  margin-top: 2.5rem !important;
  top: 50%;
  transform: translate(-50%, -50%);
}

.popoverBtn {
  // background-color: $alternateViolet;
  height: 2.8rem !important;
  width: 2.8rem !important;
  border-radius: 6px !important;
  cursor: pointer;
  font-size: 1rem !important;
  color: #fff !important;
  border: 2px solid;
}
.classifier_subClassInputColorDiv {
  margin-left: 1rem !important;
}
.popoverContent_heading {
  position: relative;
  font-size: 0.9rem !important;
  color: rgb(79, 79, 79);
}
.popoverContent {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  height: 8rem;
  overflow-y: scroll;
  padding-right: 0.5rem !important;
  /* Scrollbar width */
  &::-webkit-scrollbar {
    width: 6.5px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px !important;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.popoverContentColor {
  cursor: pointer;
  width: 25px;
  height: 25px;
  border-radius: 6px;
  margin: 0 5px 7px;
}

.subClass_left_row {
  display: flex;
  width: 50%;
  margin-left: 2rem;
  align-items: center;
}

.classifier_subClassColor {
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 6px;
  // background-color: #594bff;
}

.classifier_createBtn {
  display: block !important;
  margin: 0 0 0 auto !important;
  height: 2.8rem !important;
  background-color: $alternateViolet;

  border: 2px solid $alternateViolet;
  border-radius: 4px !important;
  font-size: 1rem !important;
  color: #fff !important;
  &:hover {
    background-color: #594bff !important;
    border: 2px solid #594bff !important;
  }
}
.classifier_hrLine {
  margin: 1.5rem 0 !important;
  border: none;
  border-bottom: 2px solid #c4c4c4 !important;
}
.emptyBoxText {
  font-size: 1.1rem;
  font-weight: 500;
  color: #c4c4c4 !important;
  text-align: center;
}
.classifier_collapse {
  margin: 1.5rem 0 2.5rem;
  border-radius: 6px !important;
  background-color: transparent !important;
}
.classifier_collapsePanel {
  background-color: transparent !important;
}
.classifier_classesHeadingArrow {
  font-size: 1.25rem !important;
  padding-top: 0.75rem !important;
}
.classifier_classesHeading {
  padding-top: 0.5rem !important;
  margin-bottom: 0.1rem !important;
  color: #333 !important;
  font-size: 1.3rem !important;
  user-select: none;
  font-weight: 500 !important;
}
.classifier_classesHeadingIcon {
  font-size: 1.25rem !important;
  padding-top: 0.75rem !important;
  padding-right: 0.65rem !important;
  color: #333 !important;
}

.classifier_classesIconBtns {
  width: 44px !important;
  height: 44px !important;
  border-radius: 50% !important;
  background-color: #fff !important;
  border: 1px solid $secondaryColor;
  margin-left: 1rem !important;
  margin-right: 0.5rem !important;
  transition: all 0.2s ease-in-out;
  & > :nth-child(n) {
    position: relative;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    transition: all 0.2s ease-in-out;
    font-size: 1.25rem !important;
  }
  &:hover {
    transition: all 0.2s ease-in-out;

    & > :nth-child(n) {
      transition: all 0.2s ease-in-out;
      color: #fff !important;
    }
  }
}
.classifier_classesEditIcon {
  & > :nth-child(n) {
    color: $alternateViolet;
  }
  &:hover {
    background-color: $alternateViolet;
  }
}
.classifier_classesDeleteIcon {
  & > :nth-child(n) {
    color: $dangerColor;
  }
  &:hover {
    background-color: $dangerColor;
  }
}
.deleteModal_deleteBtn {
  border: 2px solid $dangerColor;
  background-color: transparent !important;
  color: $dangerColor;
  &:hover {
    background-color: $dangerColor;
    color: #fff !important;
  }
}
.classifier_subClassHeadingCol {
  padding-left: 4rem !important;
}
.classifier_subClassesTableHeading {
  font-size: 1.05rem !important;
  font-weight: 500 !important;
  color: #333 !important;
}
.classifierAllSubClassDiv {
  min-height: auto;
  max-height: 18.5rem;
  overflow-y: scroll;
  /* Scrollbar width */
  &::-webkit-scrollbar {
    width: 10px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    margin-top: 1rem !important;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px !important;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.classifier_subClassRow {
  margin-top: 1rem !important;
  border-top: 1px solid #c4c4c4 !important;
  margin-left: 2rem !important;
  padding-top: 1rem !important;
}
.classifier_subClasses {
  text-align: left !important;
  padding-left: 1rem !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  margin: 0.5rem 0 !important;
}
.classifier_subClassEditIcon {
  border-radius: 6px !important;
  width: 60px !important;
  height: 37px !important;
  padding: 0.38rem 0 !important;
  background-color: #fff !important;
  border: 1px solid $secondaryColor;
  margin-right: 0.5rem !important;
  transition: all 0.2s ease-in-out;
  font-size: 0.9rem !important;
  color: $primaryColor;
  cursor: pointer;
  &:hover {
    background-color: $primaryColor;
    color: #fff !important;
  }
}
.classifier_subClassDelIcon {
  border-radius: 6px !important;
  width: 60px !important;
  height: 37px !important;
  padding: 0.38rem 0 !important;
  background-color: #fff !important;
  border: 1px solid $secondaryColor;
  margin-right: 0.5rem !important;
  transition: all 0.2s ease-in-out;
  font-size: 0.7rem !important;
  color: $dangerColor;
  cursor: pointer;
  &:hover {
    background-color: $dangerColor;
    color: #fff !important;
  }
}
.classifier_subClassHrLine {
  margin: 1rem 2rem !important;
  border: none;
  border-bottom: 2px solid #c4c4c4 !important;
}
.classifier_subClassesAddNewText {
  display: inline !important;
  font-size: 1.05rem !important;
  font-weight: 500 !important;
  color: $primaryColor;
  margin-left: 2rem !important;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.classifier_createSubClassDiv {
  display: flex;
  margin: 0.5rem 0 0.5rem 2rem !important;
  width: 55% !important;
}
.createComponent_inputFields {
  height: 2.66rem;
  font-size: 1rem !important;
  border: none;
  border: 2px solid $alternateViolet;
  border-radius: 6px 6px 0 0 !important;
  transition: all 0.3s ease-in-out;
  margin-top: 0.14rem !important;
}
.createComponent_inputNumberField {
  width: 100% !important;
  & > div:nth-child(2) > input {
    height: 2.66rem !important;
  }
}
.classifier_subClassInputLabels {
  display: block;
  position: relative;
  font-weight: 500 !important;
  font-size: 1rem !important;
}
.addNewSubClassSelect {
  width: 100% !important;
  border: 2px solid $primaryColor;
  border-radius: 4px !important;
  height: 2.66rem !important;
  font-size: 1.04rem !important;
  margin-top: 0.14rem !important;
  & > div {
    border: 2px solid $primaryColor;
    border-radius: 4px !important;
    font-size: 1.08rem !important;
    margin-left: 2rem !important;
    & > span:nth-child(2) {
      margin: auto 0 !important;
    }
  }
  & > Option {
    font-size: 1.2rem !important;
  }
}

.loader {
  height: 100%;
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
}

.loading-text {
  margin-top: 1rem;
  color: #012cff;
}
.addNewSubClassSelect_dropdown {
  display: none !important;
}
.addNewSubClassCreateBtn {
  justify-content: flex-start !important;
  margin: auto auto 0 2rem !important;
  height: 2.66rem !important;
  margin-bottom: 0.3rem !important;
}
.classifier_subClassBtnDiv {
  margin: auto 0 !important;
}

.createComponent_inputLabels {
  display: block;
  position: relative;
  font-weight: 500 !important;
  font-size: 1.1rem !important;
  top: 0.4rem !important;
}
.createComponent_inputFields {
  height: 3.2rem;
  font-size: 1rem !important;
  background-color: #eff3ff !important;
  border: none;
  border-bottom: 2px solid $alternateViolet;
  border-radius: 6px !important;
  transition: all 0.3s ease-in-out;
}
.createComponent_dropdowns {
  width: 100%;
  margin-top: 0.5rem !important;
  & > div {
    height: 3.2rem !important;
    background-color: #eff3ff !important;
    border: none !important;
    border: 2px solid $alternateViolet;
    border-radius: 6px !important;
    & > span:nth-child(2) {
      margin: auto !important;
    }
  }
  & > span {
    color: $primaryColor;
  }
}

.createComponent_btn {
  height: 3.4rem !important;
  background-color: $primaryColor;
  border: 2px solid $primaryColor;
  border-radius: 4px !important;
  font-size: 1.17rem !important;
  margin-top: 1rem !important;
  &:hover {
    background-color: #594bff !important;
    border: 2px solid #594bff !important;
  }
}
.createComponent_addClassBtn {
  position: absolute;
  bottom: 0 !important;
  height: 3.2rem !important;
  font-size: 1.07rem !important;
  background-color: transparent !important;
  color: $primaryColor;
  &:hover {
    background-color: #594bff !important;
    border: 2px solid #594bff !important;
    color: #fff !important;
  }
}

@media (max-width: $bp-md) {
  .classifier_filter {
    margin-bottom: 1.5rem !important;
  }
  .classifier_subClassRow {
    margin-left: 0.5rem !important;
  }
  .classifier_subClassHeadingCol {
    padding-left: 1rem !important;
  }
  .classifier_subClasses {
    padding-left: 0.5rem !important;
  }
  .classifier_subClassBtnDiv {
    width: 100% !important;
    display: flex;
    justify-content: space-between !important;
  }
  .classifier_subClassHrLine {
    margin: 1rem 0.5rem !important;
  }
  .classifier_subClassesAddNewText {
    margin-left: 0.5rem !important;
  }
  .classifier_createSubClassDiv {
    margin: 1rem 0 0.5rem !important;
    width: 90% !important;
  }
  .addNewSubClassSelect {
    width: 90% !important;
    & > div {
      margin-left: 0.5rem !important;
    }
  }
}
