@import "styles/variables.scss";

.createComponent_heading {
  font-size: 1.2rem;
  font-weight: 500 !important;
  color: $primaryColor;
  text-align: center;
}
.createComponent_card {
  margin: 1rem auto !important;
  padding: 0 0.5rem !important;
  width: 43%;
  border-radius: 6px !important;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.07);
}
.createComponent_inputLabels {
  display: block;
  position: relative;
  font-weight: 500 !important;
  font-size: 1.1rem !important;
  top: 0.4rem !important;
}
.createComponent_inputFields {
  height: 3.2rem;
  font-size: 1rem !important;
  background-color: #eff3ff !important;
  border: none;
  border-bottom: 2px solid $alternateViolet;
  border-radius: 6px 6px 0 0 !important;
  transition: all 0.3s ease-in-out;
}
.createComponent_dropdowns {
  width: 100%;
  margin-top: 0.5rem !important;
  & > div {
    height: 3.2rem !important;
    background-color: #eff3ff !important;
    border: none !important;
    border-bottom: 2px solid $alternateViolet;
    border-radius: 6px 6px 0 0 !important;
    & > span:nth-child(2) {
      margin: auto !important;
    }
  }
  & > span {
    color: $primaryColor;
  }
}
.createComponent_submitBtn {
  height: 3.4rem !important;
  background-color: $primaryColor;
  border: 2px solid $primaryColor;
  border-radius: 4px !important;
  font-size: 1.17rem !important;
  margin-top: 1rem !important;
  &:hover {
    background-color: #594bff !important;
    border: 2px solid #594bff !important;
  }
}

@media (max-width: $bp-md) {
  .createComponentDiv {
    height: 84vh !important;
    margin-bottom: 3rem !important;
  }
  .createComponent_heading {
    margin-top: 3vh !important;
  }
  .createComponent_card {
    width: 100%;
    padding: 0 !important;
  }
}
