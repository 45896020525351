@import "styles/variables.scss";

.ant-table {
  th {
    min-width: 200px !important;
  }
}

.loadingDiv {
  position: relative;
  width: 100%;
  height: 33rem;
}
.loadingSpinIcon {
  font-size: 20px;
  color: $alternateViolet;
}
.loadingDiv_spin {
  position: relative;
  margin: auto 0 !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.filter_inputLabels {
  position: relative;
  display: block !important;
  margin-top: 1.15rem !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
  &:nth-child(1) {
    margin-top: 0rem !important;
  }
}
.filter_inputField {
  width: 90% !important;
  & > div {
    height: 3rem !important;
    border: 2px solid $primaryColor;
    border-radius: 4px !important;
    font-size: 1rem !important;
    // margin-left: 2rem !important;
    & > span:nth-child(2) {
      margin: auto 0 !important;
    }
  }
  & > Option {
    font-size: 1.2rem !important;
  }
}
.filter_submitBtn {
  position: absolute;
  bottom: 0 !important;
  height: 3rem !important;
  width: 90% !important;
  background-color: $primaryColor;
  border: 2px solid $primaryColor;
  border-radius: 4px !important;
  font-size: 1.05rem !important;
  color: #fff !important;
  &:hover {
    background-color: #594bff !important;
    border: 2px solid #594bff !important;
  }
}
.viewOneUserAnno_hrLine {
  margin: 1.5rem 0.5rem 1.5rem 0 !important;
  border: none;
  border-bottom: 2px solid #c4c4c4 !important;
}
.table_empty {
  margin-top: 7rem !important;
}
@media (max-width: 768px) {
  .filter_inputLabels {
    font-size: 1.1rem !important;
  }
  .filter_inputField {
    width: 99% !important;
    margin-bottom: 1rem !important;
    & > div {
      height: 3.2rem !important;
    }
    & > Option {
      font-size: 1.4rem !important;
    }
  }
  .filter_submitBtn {
    width: 99% !important;
    position: relative;
    margin-top: 0.5rem !important;
  }
}

@media (max-width: 991px) and (min-width: 769px) {
  .filter_submitBtn {
    width: 90% !important;
    position: relative;
    margin-top: 0.5rem !important;
  }
}
