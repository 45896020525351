@import "styles/variables.scss";

.content {
  // padding: 1.7rem 2.2rem 1.7rem 2.8rem !important;
  padding: 1.7rem 2.2rem 1.7rem 2.8rem !important;
  // margin: 0 auto;

  min-height: 100vh;
  transition: all 0.3s ease-in-out !important;
}

.contentLayoutForCreation {
  min-height: 100vh;
}

@media screen and (max-width: 1500px) {
  .content {
    padding: 1.7rem 2.2rem 1.7rem 2.2rem !important;
  }
}

@media screen and (max-width: 900px) {
  .content {
    padding: 1.7rem 2.2rem 1.7rem 1.2rem !important;
  }
}

@media screen and (max-width: 400px) {
  .content {
    padding: 1.7rem 2.2rem 1.7rem 0.75rem !important;
  }
}
