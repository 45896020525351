@import "styles/variables.scss";

.categories_headingRow {
  margin-top: 1.3rem !important;
  width: 97%;
}
.categories_heading {
  font-size: 1.2rem;
  font-weight: 500 !important;
  color: $primaryColor;
  margin: 0 !important;
}
.categories_description {
  font-size: 0.9rem;
  font-weight: 400 !important;
  color: $secondaryColor;
  width: 60%;
  margin: 0 !important;
}
.categories_searchBox {
  float: right;
  width: 50% !important;
  height: 2.8rem;
  border: 2px solid $primaryColor;
  border-radius: 4px;
}
.categories_searchBoxIcon {
  margin-right: 2px !important;
  font-size: 1.36rem;
  color: #a1a1a1 !important;
}
.loadingDiv {
  position: relative;
  width: 100%;
  height: 33rem;
}
.loadingSpinIcon {
  font-size: 20px;
  color: $alternateViolet;
}
.loadingDiv_spin {
  position: relative;
  margin: auto 0 !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.categories_row {
  width: 97%;
  margin-top: 1rem !important;
}
.categories_col {
  margin-top: 1rem !important;
}
.categories_card {
  width: 90%;
  padding: 0.6rem 1.2rem 1rem !important;
  border-radius: 6px !important;
  background-color: #fff !important;
  box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.06);
}
.categoriesCard_infoIcon {
  margin-left: 0.5rem !important;
  font-size: 1rem !important;
  color: $secondaryColor;
  vertical-align: middle;
  padding-bottom: 0.15rem !important;
}
.categories_cardOptionsBtn {
  padding: 0rem 0.4rem !important;
  border-radius: 4px !important;
  height: 2rem !important;
  margin-left: 0.4rem !important;
  &:nth-child(n) {
    font-size: 2.1rem !important;
  }
  &:hover {
    background-color: #dedbff !important;
    color: $primaryColor;
    cursor: pointer;
  }
  &:nth-child(2) {
    &:hover {
      background-color: #ffe2db !important;
      color: #ff4a4a !important;
    }
  }
}
.categoriesCard_popoverOptions {
  font-size: 1.05rem !important;
  font-weight: 500 !important;
  color: $primaryColor;
  &:hover {
    cursor: pointer;
  }
}
.categoriesCard_type {
  font-size: 1.66rem !important;
  font-weight: 500 !important;
  margin-bottom: 0 !important;
}
.categoriesCard_name {
  font-size: 0.9rem !important;
  font-weight: 400 !important;
  color: $secondaryColor;
  text-transform: uppercase;
  margin-top: 0.35rem !important;
}
.categoriesCard_dbAndImgsRow {
  margin-top: 1.3rem !important;
}
.categoriesCard_dbAndImgs {
  display: flex;
  align-items: center;
}
.categoriesCard_db_img_text {
  font-weight: 400 !important;
  font-size: 1.15rem !important;
  color: $primaryColor;
}
.categoriesCard_link {
  width: 100%;
}
.categoriesCard_btn {
  width: 100%;
  display: block !important;
  height: 2.8rem !important;
  border-radius: 4px !important;
  font-size: 1.12rem !important;
  margin: 1rem 0 0 auto !important;
  color: #fff !important;
}
.categoriesCard_btnArrowIcon {
  margin-left: 0.5rem !important;
  font-size: 1.08rem;
}

@media (max-width: $bp-md) {
  .categories_description {
    width: 100%;
  }
  .categories_searchBox {
    float: left;
    width: 90% !important;
    height: 3.2rem;
    margin: 1rem 0 0 !important;
  }
  .categories_row {
    width: 100%;
  }
  .categories_card {
    width: 100%;
  }
}
