@import "styles/variables.scss";

.buckets_goBackArrow {
  margin-right: 0.3rem !important;
  margin-bottom: 0.25rem !important;
  color: #333 !important;
}
.buckets_goBackBtn {
  font-size: 0.85rem !important;
  margin-bottom: 0 !important;
}
.buckets_headingRow {
  margin-top: 1.3rem !important;
  width: 97%;
}
.buckets_heading {
  font-size: 1.2rem;
  font-weight: 500 !important;
  color: $primaryColor;
  margin: 0 !important;
}
.buckets_description {
  font-size: 1.1rem !important;
  font-weight: 400 !important;
  margin: 1rem 0 0 !important;
  & > hr {
    display: inline;
    height: 1.8rem !important;
    margin: 0 0.6rem !important;
    border: none;
    border-left: 1px solid #000 !important;
  }
  & > .buckets_descriptionCamera {
    margin-right: 0.3rem !important;
    margin-bottom: 0.25rem !important;
  }
}
.buckets_searchBox {
  width: 50% !important;
  height: 2.8rem;
  margin: 2rem 0 0 !important;
  border: 2px solid $primaryColor;
  border-radius: 4px;
}
.buckets_searchBoxIcon {
  margin-right: 2px !important;
  font-size: 1.36rem;
  color: #a1a1a1 !important;
}
.buckets_filterHeading {
  font-size: 1.04rem !important;
  font-weight: 500 !important;
  display: inline;
  color: $primaryColor;
  margin-right: 0.8rem !important;
}
.buckets_filter {
  & > div {
    height: 2.6rem !important;
    border: 2px solid $primaryColor;
    border-radius: 4px !important;
    font-size: 1.04rem !important;
    & > span:nth-child(2) {
      margin: auto 0 !important;
    }
  }
  & > Option {
    font-size: 1.2rem !important;
  }
}
.filterCol {
  position: absolute;
  bottom: 0 !important;
  right: 0 !important;
}
.buckets_row {
  width: 100%;
  margin-top: 1.5rem !important;
  margin-bottom: 4rem !important;
}
.buckets_col {
  margin-top: 1.4rem !important;
}
.buckets_card {
  width: 83%;
  padding: 0.8rem 1.2rem 1rem !important;
  border-radius: 6px !important;
  background-color: #fff !important;
  box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.06);
}
.bucketsCard_name {
  font-size: 1.66rem !important;
  font-weight: 500 !important;
  margin-bottom: 0 !important;
}
.bucketsCard_imgCount {
  font-size: 1.04rem !important;
  font-weight: 400 !important;
  margin-top: 0.35rem !important;
}
.bucketsCard_dbAndImgsRow {
  margin: 1.3rem 0 1rem !important;
}
.bucketsCard_dbAndImgs {
  font-weight: 400 !important;
  font-size: 1.15rem !important;
  & > span {
    position: relative;
    top: 0.13rem;
    left: 0.27rem;
    color: $primaryColor;
  }
}
.bucketsCard_btn {
  display: block !important;
  height: 2.8rem !important;
  border-radius: 4px !important;
  font-size: 1.12rem !important;
  margin: 1.5rem 0 0 auto !important;
  color: #fff !important;
  border: none;
  &:hover {
    background-color: #594bff !important;
  }
}
.bucketsCard_btnArrowIcon {
  margin-left: 0.5rem !important;
  font-size: 1.08rem;
}

.loadingDiv {
  position: relative;
  width: 100%;
  height: 33rem;
}
.loadingSpinIcon {
  font-size: 20px;
  color: $alternateViolet;
}
.loadingDiv_spin {
  position: relative;
  margin: auto 0 !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.errorDiv {
  position: relative;
  width: 100%;
  height: 30vh;
}
.buckets_errorMsg {
  position: relative;
  font-size: 1.2rem !important;
  font-weight: 500 !important;
  color: $dangerColor;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: $bp-md) {
  .buckets_description {
    width: 100%;
  }
  .buckets_searchBox {
    float: left;
    width: 90% !important;
    height: 3.2rem;
    margin: 1rem 0 0 !important;
  }
  .filterCol {
    position: relative;
    margin-top: 1.5rem !important;
  }
  .buckets_row {
    width: 100%;
  }
  .buckets_card {
    width: 100%;
  }
}
