@import "styles/variables.scss";

.images_createAnnotationDiv {
  overflow-y: auto;
  // height: 90vh;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}
.searchImg_filterHeading {
  padding-left: 1.5rem;
  margin-top: 1.5rem;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: $primaryColor;
  }
}
.images_radioBucketHeading {
  font-weight: 500 !important;
  margin: 1rem 0 0 1.5vw !important;
  width: 85%;
}
.images_datasetHeading {
  color: $primaryColor;
  margin: 1rem 0 0 1.5vw !important;
}
.images_radioBucketNames {
  display: block;
  font-size: 1.02rem !important;
}
.images_classesFilter {
  margin: 1rem 0 0 1.5vw !important;
  width: 85% !important;

  & > div {
    height: 2.6rem !important;
    border: 2px solid $primaryColor;
    border-radius: 4px !important;
    font-size: 1.02rem !important;
    & > span:nth-child(2) {
      margin: auto 0 !important;
    }
  }
  & > Option {
    font-size: 1.2rem !important;
  }
}
.images_allCheckboxSubClassesDiv {
  min-height: 0vh;
  max-height: 37vh;
  overflow-y: auto;
  margin-top: 1rem !important;
 // -ms-overflow-style: none; /* Internet Explorer 10+ */
//  scrollbar-width: none; /* Firefox */
 // &::-webkit-scrollbar {
 //   display: none; /* Safari and Chrome */
 // }
}
.images_radioBucketSubclasses {
  display: block;
  margin-left: 1.5vw !important;
  margin-top: 0.7rem !important;
}
.images_radioBucketSubclasses_label {
  font-size: 1.2rem !important;
  margin-bottom: 0.2rem !important;
  margin-right: 0.3rem !important;
}
.images_radioBucketSubclassesName {
  font-size: 1.02rem !important;
}
.images_radioSubClassCount {
  display: inline !important;
  margin: 0 0.5rem !important;
  font-weight: 500 !important;
}
.images_commentBoxInput {
  margin: 1.5rem 1.3rem 0;
  width: 87%;
}
.images_saveForLaterCheckbox {
  margin: 0.5rem 1.3rem 0;
}
.images_annotationSaveBtnDiv {
  margin: 1rem auto;
  text-align: center;
}
.images_imgControlBtns {
  border: none;
  border-radius: 4px !important;
  font-size: 1rem !important;
  margin: 0 0.2rem !important;
  height: 2.3rem !important;
}
.images_imgControlSaveBtns {
  background-color: $primaryColor;
  color: #fff;
  border: none;
  border-radius: 4px !important;
  font-size: 1rem !important;
  margin: 0.5rem 0.2rem !important;
  height: 2.3rem !important;
  user-select: none !important;
  &:hover {
    color: #fff !important;
    background-color: #594bff !important;
  }
}
.images_reviewTable {
  margin: 1rem 0 0 1.5vw !important;
  width: 85% !important;
  & > thead > tr > th {
    font-size: 1.2rem !important;
    font-weight: 500 !important;
  }
  & > tbody > tr > td {
    font-size: 1.02rem !important;
  }
}
.annotationReviewTable_agreeBtn {
  cursor: pointer;
  font-weight: 500 !important;
  &:hover {
    text-decoration: underline;
  }
}
.tableCol_infoIcon {
  margin-left: 0.25rem !important;
  font-size: 1rem !important;
  color: $secondaryColor;
  vertical-align: middle;
  padding-bottom: 0.15rem !important;
}

@media (max-width: $bp-md) {
  .images_createAnnotationDiv {
    height: auto;
  }
  .images_radioBucketHeading {
    font-size: 1.7rem !important;
    margin: 2rem 0 0 1.5rem !important;
  }
  .images_radioBucketNames {
    font-size: 1.3rem !important;
  }
  .images_radioBucketSubclasses {
    margin-left: 1.5rem !important;
  }
  .images_classesFilter {
    margin: 1rem 0 0 1.5rem !important;
  }
  .images_annotationSaveBtnDiv {
    margin: 1rem auto 3rem;
    text-align: center;
  }
  .images_imgControlSaveBtns {
    margin: 0.5rem 1.2rem !important;
  }
  .container{
    display: flex;
    justify-content: center;
  }
  .classContainer
  {
    display: flex;
    justify-content: center;
  }
}
