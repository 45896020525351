@import "styles/variables.scss";

.table_annotationsHeading {
  font-size: 1.4rem !important;
  font-weight: 500 !important;
  color: $primaryColor;
}
.table {
  width: 99% !important;
  margin: 1.2rem 0 3rem !important;
  & > div > div > div {
    padding-left: 1rem !important;
    font-size: 1rem !important;
  }
}
