@import "styles/variables.scss";

.classifiers_headingRow {
  margin-top: 1.3rem !important;
  width: 97%;
}
.classifiers_heading {
  font-size: 1.2rem;
  font-weight: 500 !important;
  color: $primaryColor;
  margin: 0 !important;
}
.classifiers_description {
  font-size: 0.9rem;
  font-weight: 400 !important;
  color: $secondaryColor;
  width: 60%;
  margin: 0 !important;
}
.classifiers_searchBox {
  float: right;
  width: 50% !important;
  height: 2.8rem;
  margin: 0.3rem 0 0 !important;
  // margin: 2rem 0 0 !important;
  border: 2px solid $primaryColor;
  border-radius: 4px;
}
.classifiers_searchBoxIcon {
  margin-right: 2px !important;
  font-size: 1.36rem;
  color: #a1a1a1 !important;
}
.classifiers_row {
  width: 97%;
  margin-top: 1.5rem !important;
}
.classifiers_col {
  margin-top: 1rem !important;
}
.classifiers_card {
  width: 90%;
  padding: 0.6rem 1.2rem 1rem !important;
  border-radius: 6px !important;
  background-color: #fff !important;
  box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.06);
}
.classifiers_cardEditBtn {
  padding: 0rem 0.6rem 0.2rem !important;
  margin-left: 1rem !important;
  border-radius: 4px !important;
  &:nth-child(n) {
    font-size: 1.45rem !important;
  }
  &:hover {
    background-color: #dedbff !important;
    color: $primaryColor;
    cursor: pointer;
  }
  &:nth-child(2) {
    &:hover {
      background-color: #ffe2db !important;
      color: #ff4a4a !important;
    }
  }
}
.classifiersCard_type {
  font-size: 1.66rem !important;
  font-weight: 500 !important;
  margin-bottom: 0 !important;
}
.classifiersCard_name {
  font-size: 0.9rem !important;
  font-weight: 400 !important;
  color: $secondaryColor;
  text-transform: uppercase;
  margin-top: 0.35rem !important;
}
.classifiersCard_dbAndImgsRow {
  margin-top: 1.3rem !important;
}
.classifiersCard_dbAndImgs {
  font-weight: 400 !important;
  font-size: 1.15rem !important;
  & > span {
    position: relative;
    top: 0.13rem;
    left: 0.27rem;
    color: $primaryColor;
  }
}
.classifiersCard_btn {
  display: block !important;
  height: 2.8rem !important;
  background-color: $primaryColor;
  border: 2px solid $primaryColor;
  border-radius: 4px !important;
  font-size: 1.12rem !important;
  margin: 1rem 0 0 auto !important;
  color: #fff !important;
  &:hover {
    background-color: #594bff !important;
    border: 2px solid #594bff !important;
  }
}
.classifiersCard_btnArrowIcon {
  margin-left: 0.5rem !important;
  font-size: 1.08rem;
}

@media (max-width: $bp-md) {
  .classifiers_description {
    width: 100%;
  }
  .classifiers_searchBox {
    float: left;
    width: 90% !important;
    height: 3.2rem;
    margin: 1rem 0 0 !important;
  }
  .classifiers_row {
    width: 100%;
  }
  .classifiers_card {
    width: 100%;
  }
}
