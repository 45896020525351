@import "styles/variables.scss";

.searchImage__container {
  padding-right: 1.2rem;
}

.searchImg_heading {
  font-size: 1.2rem;
  font-weight: 500 !important;
  color: $primaryColor;
  margin-top: 1.3rem !important;
  margin-bottom: 0 !important;
}
.searchImg_filterHeading {
  font-size: 1.04rem !important;
  font-weight: 500 !important;
  color: $primaryColor;
  margin-top: 0.8rem !important;
  margin-right: 0.8rem !important;
  margin-bottom: 0 !important;
}
.searchImg_filter {
  margin-top: 0 !important;
  & > div {
    height: 2.6rem !important;
    border: 2px solid $primaryColor;
    border-radius: 4px !important;
    font-size: 1.04rem !important;
    & > span:nth-child(2) {
      margin: auto 0 !important;
    }
  }
  & > Option {
    font-size: 1.2rem !important;
  }
}
.searchImg_input {
  height: 2.6rem !important;
  border: 2px solid $primaryColor;
  border-radius: 4px !important;
  font-size: 1.15rem !important;
  margin-right: 2rem;
}
.searchImg_inputNumberField {
  font-size: 1rem !important;
  border: none;
  border: 2px solid $alternateViolet;
  border-radius: 6px 6px 0 0 !important;
  transition: all 0.3s ease-in-out;
  margin-left: 0.7rem;
  width: 90% !important;
  margin-left: 0;
  height: 2.66rem;
  & > div:nth-child(2) > input {
    height: 2.46rem !important;
  }
}
.searchImg_searchBox {
  width: 60% !important;
  float: right;
  height: 2.8rem;
  margin: 1.7rem 1.5rem 0 0 !important;
  border: 2px solid $primaryColor;
  border-radius: 4px;
}
.searchImg_searchBoxIcon {
  margin-right: 2px !important;
  font-size: 1.36rem;
  color: #a1a1a1 !important;
}
.searchImg_btn {
  display: block !important;
  height: 2.8rem !important;
  border-radius: 4px !important;
  font-size: 1rem !important;
  margin: 2.2rem 0 0 !important;
  padding: 0 1.75rem;
  color: #fff !important;
  background-color: $primaryColor;
  border: none;
  &:hover {
    background-color: #594bff !important;
  }
}
.searchImg_pagination {
  position: relative;
  margin: 2rem 2.5rem 1.5rem 0 !important;
  float: right;
}
.loadingDiv {
  position: relative;
  width: 100%;
  height: 33rem;
}
.loadingSpinIcon {
  font-size: 20px;
  color: $alternateViolet;
}
.loadingDiv_spin {
  position: relative;
  margin: auto 0 !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.errorDiv {
  position: relative;
  width: 100%;
  height: 30vh;
}
.errorMsgHeading {
  position: relative;
  font-size: 1.2rem !important;
  font-weight: 500 !important;
  color: $dangerColor;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
}
.table {
  width: 97% !important;
  margin-top: 1.8rem !important;
  & > div > div > div {
    padding-left: 0.3rem !important;
    font-size: 1rem !important;
  }
}
.imagesDataTableCol_infoIcon {
  margin-left: 0.5rem !important;
  font-size: 1rem !important;
  color: $secondaryColor;
  vertical-align: middle;
  padding-bottom: 0.15rem !important;
}
.table_previewHeading {
  cursor: pointer;
  color: $alternateViolet;
  font-weight: 500;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
.previewModal_img {
  width: 90%;
}
.table_visitBtn {
  display: block !important;
  height: 2.8rem !important;
  background-color: $alternateViolet;
  border: 2px solid $alternateViolet;
  border-radius: 4px !important;
  font-size: 1rem !important;
  color: #fff !important;
  &:hover {
    background-color: #594bff !important;
    border: 2px solid #594bff !important;
  }
}
