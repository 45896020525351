@import "styles/variables.scss";

.navbar_profile_bellIcon {
  font-size: 1.5rem !important;
}
.notification_popoverHeading {
  font-size: 1.1rem !important;
}
.notifications_popoverMarkAllRead {
  color: $primaryColor;
  float: right !important;
}
