@import "styles/variables.scss";

.metrics_heading {
  font-size: 1.2rem;
  font-weight: 500 !important;
  color: $primaryColor;
  text-align: center;
}
.uploadComponent_card {
  margin: 1rem auto !important;
  padding: 0 0.5rem !important;
  width: 43%;
  border-radius: 6px !important;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.07);
}
.metrics_emptyTableText {
  margin-top: 1rem;
  color: #afafaf;
}

.uploadDataset_chooseTypeHeading {
  font-size: 1.4rem !important;
  color: $secondaryColor;
}
.uploadDataset_chooseDivs {
  border: 2px solid $primaryColor;
  border-radius: 4px;
  height: 6rem;
  margin-bottom: 2rem !important;
  // cursor: pointer;
}
.uploadDataset_chooseDiv_typeHeading {
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.1rem !important;
  color: rgb(124, 139, 255) !important;
}
.upload_uploadedFileText {
  text-align: center;
  margin: auto 0 !important;
  color: $secondaryColor;
  font-weight: 500 !important;
  & > span {
    position: relative;
    top: 0.2rem !important;
    font-size: 1.1rem !important;
  }
}
.upload_uploadedFileExcelIcon {
  color: rgb(111, 127, 245) !important;
  font-size: 3rem !important;
  display: block;
  margin: 0 auto !important;
}
.upload_uploadedFileDiv {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.upload_uploadedFileTick {
  color: green !important;
  font-size: 1.4rem !important;
  margin-right: 0.2rem;
}
.upload_openPreview {
  margin-top: 0.6rem !important;
  text-align: center;
  color: rgb(171, 171, 171);
  font-size: 1rem !important;
  font-weight: 500 !important;
  & > span {
    cursor: pointer;
    font-size: 0.9rem !important;
    margin-right: 0.2rem !important;
  }
  &:hover {
    color: $alternateViolet;
  }
}
.upload_uploadedFileDivCloseBtn {
  position: absolute;
  font-size: 1.3rem !important;
  color: rgb(174, 174, 174);
  right: 7px;
  top: 5px;
  cursor: pointer;
  z-index: 1;
  &:hover {
    color: rgb(255, 104, 104);
  }
}
.upload_uploadFilePart {
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  cursor: pointer;
}
.upload_submitBtn {
  height: 3.4rem !important;
  background-color: $primaryColor;
  border: 2px solid $primaryColor;
  border-radius: 4px !important;
  font-size: 1.13rem !important;
  &:hover {
    background-color: #594bff !important;
    border: 2px solid #594bff !important;
  }
}
.metrics_inputLabels {
  position: relative;
  display: block !important;
  margin-top: 1.15rem !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
  &:nth-child(1) {
    margin-top: 0rem !important;
  }
}
.metrics_inputField {
  margin: 0.5rem 0 0.3 !important;
  height: 3.2rem;
  font-size: 1rem !important;
  border: 2px solid $alternateViolet;
  border-radius: 6px !important;
  transition: all 0.3s ease-in-out;
}
.metrics_dropDownField {
  width: 100% !important;
  &:last-child {
    margin-bottom: 1rem !important;
  }
  & > div {
    height: 3rem !important;
    border: 2px solid $primaryColor;
    border-radius: 4px !important;
    font-size: 1rem !important;
    // margin-left: 2rem !important;
    & > span:nth-child(2) {
      margin: auto 0 !important;
    }
  }
  & > Option {
    font-size: 1.2rem !important;
  }
}
.upload_backBtn {
  text-align: left;
  cursor: pointer;
}
.metrics_uploadBtn {
  cursor: pointer;
  display: block !important;
  margin: 1.3rem auto 0 !important;
  padding: 0.4rem !important;
  width: 7.3rem !important;
  background-color: $alternateViolet;
  border: 2px solid $alternateViolet;
  border-radius: 4px !important;
  font-size: 1rem !important;
  color: #fff !important;
  text-align: center;
  &:hover {
    background-color: #3122cc !important;
    border: 2px solid #3122cc !important;
  }
}
.metrics_topUploadBtn {
  display: block !important;
  margin: 0 0 0 auto !important;
  padding: 0.6rem 1.1rem !important;
  font-size: 0.9rem !important;
}
.upload_uploadNewPopoverBtn {
  display: block !important;
  font-size: 0.95rem !important;
  font-weight: 500 !important;
  margin-bottom: 0.5rem !important;
  &:hover {
    cursor: pointer;
    color: rgb(111, 127, 245) !important;
  }
}
.metrics_uploadBtn {
  width: 10rem !important;
}
.metrics_uploadBtnIcon {
  margin-right: 8px;
}
.metrics_uploadedFileNameDiv {
  margin-left: 0.8rem;
  & > span {
    font-size: 0.95rem;
  }
}

@media (max-width: $bp-md) {
  .createComponentDiv {
    height: 80vh !important;
  }
  .metrics_heading {
    margin-top: 3vh !important;
  }
  .uploadComponent_card {
    width: 100%;
    padding: 0 !important;
  }
}
