@import "styles/variables.scss";

// start
.ImageViewer__container {
  width: 100%;
  height: 100%;
  // border: 5px solid pink;
  background-color: #fff;
  display: grid;
  grid-template-rows: 3rem 1fr 0.25fr;
  align-content: start;
}
.ImageViewer__titleContainer {
  padding: 2rem 0 0 3rem;
  display: flex;
  justify-content: start;
  align-items: center;
}
.ImageViewer__imageAnnotatorContainer {
  // border: 5px solid green;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 3rem 0 0 0;
  width: 100%;
  height: 100%;

  & > img {
    max-height: 100%;
    max-width: 90%;
    object-fit: contain;
  }
  & > img:first-child {
    position: absolute;
    // this 16px is for adjusting the visible gap between the upper layer( markerArea) and lower layer ( reference image )
    top: calc(8% + 12px);
   // left: 50%;
    bottom: 0;
    z-index: -3;
    transform: translate(-50%, -50%);
  }

  & > img:last-child {
    z-index: 5;
  }
}

.ImageViewer__skeleton_container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  // padding: 3rem 0 1rem 0;
  width: 100%;
  height: 100%;
  // border: 5px solid red;
}

.ImageViewer__imageViewerContainer {
  // border: 5px solid blue;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 3rem 0 0 0;
}

.ImageViewer__image {
  max-height: 100%;
  max-width: 90%;
  object-fit: contain;
}

.ImageViewer__transformButtonContainer {
  position: absolute;
  z-index: 10;
  bottom: 5%;
  right: 8%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.images_imgControlBtns {
  border: none;
  border-radius: 4px !important;
  font-size: 1rem !important;
  margin: 0 0.2rem !important;
  height: 2.3rem !important;
}

.images_prevAndNextBtnDiv {
  height: 100%;
  // border: 1px solid red;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 1.5rem;
}
.images_prevAndNextBtn {
  height: 2.8rem !important;
  border: none;
  border-radius: 4px !important;
  font-size: 1.12rem !important;
  margin: 0 1rem !important;
}
.images_prevAndNextBtn_arrowLeft {
  margin-right: 0.5rem;
  margin-bottom: 0.2rem;
}
.images_prevAndNextBtn_arrowRight {
  margin-left: 0.5rem;
  margin-bottom: 0.2rem;
}

// end
