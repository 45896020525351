@import "styles/variables.scss";

.updateComponent_inputLabels {
  position: relative;
  font-weight: 500 !important;
  font-size: 1rem !important;
  top: 0.4rem !important;
}
.updateComponent_inputFields {
  margin: 0.5rem 0 !important;
  height: 3.2rem;
  font-size: 1rem !important;
  background-color: #eff3ff !important;
  border: none;
  border-bottom: 2px solid $alternateViolet;
  border-radius: 6px 6px 0 0 !important;
  transition: all 0.3s ease-in-out;
}
.updateComponent_dropdowns {
  width: 100%;
  margin-top: 0.5rem !important;
  & > div {
    height: 3.2rem !important;
    background-color: #eff3ff !important;
    border: none !important;
    border-bottom: 2px solid $alternateViolet;
    border-radius: 6px 6px 0 0 !important;
    & > span:nth-child(2) {
      margin: auto !important;
    }
  }
  & > span {
    color: $primaryColor;
  }
}
