@import "styles/variables.scss";

.classes_heading {
  font-size: 1.2rem;
  font-weight: 500 !important;
  color: $primaryColor;
  margin-top: 0.6rem !important;
}
.classes_description {
  font-size: 0.9rem;
  font-weight: 400 !important;
  color: $secondaryColor;
  width: 60%;
  margin: 0 !important;
}
.filterCol {
  margin-top: 1.6rem !important;
}
.classes_filterHeading {
  font-size: 1.04rem !important;
  font-weight: 500 !important;
  display: inline;
  color: $primaryColor;
  margin-right: 0.8rem !important;
}
.classes_filter {
  & > div {
    height: 2.6rem !important;
    border: 2px solid $primaryColor;
    border-radius: 4px !important;
    font-size: 1.04rem !important;
    & > span:nth-child(2) {
      margin: auto 0 !important;
    }
  }
  & > Option {
    font-size: 1.2rem !important;
  }
}
.deleteSubClassesBtn {
  display: block !important;
  height: 2.8rem !important;
  color: $dangerColor;
  border: 2px solid $dangerColor;
  border-radius: 4px !important;
  font-size: 1.12rem !important;
  margin: 1rem 0 0 auto !important;
  &:hover {
    color: #fff !important;
    background-color: $dangerColor;
    border: 2px solid $dangerColor;
  }
}
