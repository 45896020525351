@import "styles/variables.scss";

.sidebarDiv {
  overflow-y: scroll;
  height: 100vh;
  background-color: #fff;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  transition: all 0.3s ease-in-out;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}
.sidebarLayout {
  width: 100% !important;
  max-width: 100% !important;
  background-color: #fff;
}
.sidebarLogoDiv {
  display: flex;
  justify-content: space-around;
  margin: 0.7rem 0 0.8rem !important;
}
.sidebarLogo {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  font-weight: 700;
  font-size: 25px;
  color: #000865;
  & > span {
    color: #6571ff;
    font-weight: 300;
  }
  &:hover {
    color: #000865;
  }
}
.sidebar_subHeadings {
  color: #8592a9;
  font-size: 0.71rem;
  font-weight: 500;
  font-family: "Euclid Circular A", sans-serif;
  cursor: default;
  width: 100%;
  height: 1.2rem;
  user-select: none;
  letter-spacing: 1.5px;
}

.toggleCollapseBtn {
  font-size: 1.2rem !important;
  margin: auto 0;
}
