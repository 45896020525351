* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.root {
  width: 100vw;
  // border : 5px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
}
.root > div {
  // border: blue 1px solid;
  margin: auto;
  width: 40rem;
  height: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

// img{
//   max-width: 100%;
//   object-fit: cover;
// }

.annotator__container {
  position: relative;
}

/* 
html,
body {
  padding: 0px;
  margin: 0px;
} */

#app {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
}

@media (min-width: 860px) {
  #app {
    grid-template-columns: 1fr 4fr 15rem;
  }
}

@media (max-width: 860px) {
  #app {
    grid-template-rows: repeat(3, 1fr);
  }
}

@media (min-width: 1180px) {
  #app {
    grid-template-columns: 1fr 4fr 20rem;
  }
}

@media (min-width: 1400px) {
  #app {
    grid-template-columns: 1fr 4fr 25rem;
  }
}

@media (min-width: 2280px) {
  #app {
    grid-template-columns: 1fr 4fr 1fr;
  }
}
