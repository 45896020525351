@import "styles/variables.scss";

.metrics_inputLabels {
  position: relative;
  display: block !important;
  margin-top: 1.15rem !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
  &:nth-child(1) {
    margin-top: 0rem !important;
  }
}
.metrics_inputField {
  margin: 0.5rem 0 0.3 !important;
  height: 3.2rem;
  font-size: 1rem !important;
  border: 2px solid $alternateViolet;
  border-radius: 6px !important;
  transition: all 0.3s ease-in-out;
}
.metrics_dropDownField {
  width: 100% !important;
  &:last-child {
    margin-bottom: 1rem !important;
  }
  & > div {
    height: 3rem !important;
    border: 2px solid $primaryColor;
    border-radius: 4px !important;
    font-size: 1rem !important;
    // margin-left: 2rem !important;
    & > span:nth-child(2) {
      margin: auto 0 !important;
    }
  }
  & > Option {
    font-size: 1.2rem !important;
  }
}
.metrics_imgSliderCount {
  position: relative;
  top: 45%;
  transform: translateY(-50%);
  padding-left: 0.5rem;
}
.metrics_createFormInfoIcon {
  margin-left: 0.5rem !important;
  font-size: 1rem !important;
  color: $secondaryColor;
  vertical-align: middle;
  padding-bottom: 0.15rem !important;
}
