@import "styles/variables.scss";

.cameras_cameraIcon {
  margin-right: 0.3rem !important;
  margin-bottom: 0.25rem !important;
  color: #333 !important;
}
.cameras_headingRow {
  margin-top: 0.7rem !important;
  width: 97%;
}
.cameras_heading {
  font-size: 1.2rem;
  font-weight: 500 !important;
  color: $primaryColor;
  margin-top: 1.3rem !important;
}
.cameras_description {
  font-size: 0.9rem;
  font-weight: 400 !important;
  color: $secondaryColor;
  width: 60%;
  margin: 0 !important;
}
.cameras_searchBox {
  width: 50% !important;
  height: 2.8rem;
  margin: 0.3rem 0 0 !important;
  // margin: 2rem 0 0 !important;
  border: 2px solid $primaryColor;
  border-radius: 4px;
}
.cameras_filterHeading {
  font-size: 1.04rem !important;
  font-weight: 500 !important;
  display: inline;
  color: $primaryColor;
  margin-right: 0.8rem !important;
}
.cameras_filter {
  & > div {
    height: 2.6rem !important;
    border: 2px solid $primaryColor;
    border-radius: 4px !important;
    font-size: 1.04rem !important;
    & > span:nth-child(2) {
      margin: auto 0 !important;
    }
  }
  & > Option {
    font-size: 1.2rem !important;
  }
}
.filterCol {
  position: absolute;
  bottom: 0 !important;
  right: 0 !important;
}
.cameras_row {
  width: 100%;
  margin-top: 1rem !important;
}
.cameras_col {
  margin-top: 1rem !important;
}
.cameras_card {
  width: 90%;
  padding: 0.6rem 1.2rem 1rem !important;
  border-radius: 6px !important;
  background-color: #fff !important;
  box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.06);
}
.camerasCard_infoIcon {
  margin-left: 0.5rem !important;
  font-size: 1rem !important;
  color: $secondaryColor;
  vertical-align: middle;
  padding-bottom: 0.15rem !important;
}
.cameras_cardOptionsBtn {
  padding: 0rem 0.4rem !important;
  border-radius: 4px !important;
  height: 2rem !important;
  margin-left: 0.4rem !important;
  &:nth-child(n) {
    font-size: 2.1rem !important;
  }
  &:hover {
    background-color: #dedbff !important;
    color: $primaryColor;
    cursor: pointer;
  }
  &:nth-child(2) {
    &:hover {
      background-color: #ffe2db !important;
      color: #ff4a4a !important;
    }
  }
}
.camerasCard_popoverOptions {
  font-size: 1.05rem !important;
  font-weight: 500 !important;
  color: $primaryColor;
  &:hover {
    cursor: pointer;
  }
}
.camerasCard_type {
  font-size: 1.66rem !important;
  font-weight: 500 !important;
  margin-bottom: 0 !important;
}
.camerasCard_name {
  font-size: 0.9rem !important;
  font-weight: 400 !important;
  color: $secondaryColor;
  text-transform: uppercase;
  margin-top: 0.35rem !important;
}
.camerasCard_dbAndImgsRow {
  margin-top: 1.3rem !important;
}
.camerasCard_dbAndImgs {
  font-weight: 400 !important;
  font-size: 1.15rem !important;
  & > span {
    position: relative;
    top: 0.13rem;
    left: 0.27rem;
    color: $primaryColor;
  }
}
.camerasCard_dbAndImgs {
  display: flex;
  align-items: center;
}
.camerasCard_db_img_text {
  font-weight: 400 !important;
  font-size: 1.15rem !important;
  color: $primaryColor;
}
.camerasCard_link {
  width: 100%;
}
.camerasCard_btn {
  width: 100%;
  display: block !important;
  height: 2.8rem !important;
  border-radius: 4px !important;
  font-size: 1.12rem !important;
  margin: 1rem 0 0 auto !important;
  color: #fff !important;
}
.camerasCard_btnArrowIcon {
  margin-left: 0.5rem !important;
  font-size: 1.08rem;
}

.loadingDiv {
  position: relative;
  width: 100%;
  height: 33rem;
}
.loadingSpinIcon {
  font-size: 20px;
  color: $alternateViolet;
}
.loadingDiv_spin {
  position: relative;
  margin: auto 0 !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: $bp-md) {
  .cameras_headingRow {
    margin-top: 0 !important;
  }
  .cameras_searchBox {
    float: left;
    width: 90% !important;
    height: 3.2rem;
    margin: 1rem 0 0 !important;
  }
  .filterCol {
    position: relative;
    margin-top: 1.5rem !important;
  }
  .cameras_card {
    width: 100%;
  }
}
